import './styles/App.css'; 

import React, { useEffect } from "react";
import Stack from 'react-bootstrap/Stack';
import 'bootstrap/dist/css/bootstrap.min.css'; /* notably for tooltip arrows! */

import { Autocomplete } from './components/Autocomplete';
import CategoryList from "./components/CategoryList";
import DurationSelect from './components/DurationSelect';
import DestinationOverlay  from "./components/DestinationOverlay"
import DestinationList from "./components/DestinationList"
import LeafletMap from "./map/LeafletMap";

import { useDispatch, useSelector } from 'react-redux';
import { fetchStopTypes, fetchStops } from './actions/stopsActions';
import Header from './components/Header';
import { fetchMaps } from './actions/mapSelectActions';
import { fetchDestinationDurationMap } from './actions/destinationActions';
import { fetchTravelLegs } from './actions/travelLegsActions';
import { selectFocusDestinationId, selectFocusRawMapData, selectMapSelect, selectSelectArrId, selectSelectedStartStop } from './selectors/selector';


const SIDEBAR_WIDTH = "500px"
const ObjectList = ({ data, excludeKeys = [] }) => {
  const renderList = (obj) => {
    return !obj ? null : (
      <ul>
        {Object.keys(obj)
          .filter((key) => !excludeKeys.includes(key))
          .map((key, index) => (
            <li key={index}>
              <strong>{key}: </strong>
              {typeof obj[key] === 'object' ? (
                renderList(obj[key])
              ) : (
                obj[key]
              )}
            </li>
          ))}
      </ul>
    );
  };

  return !data ? null : renderList(data);
};

function App() {

  const dispatch = useDispatch();
  const stopList = useSelector((state) => state.stops.list);
  const maps = useSelector((state) => state.maps.maps);
  const selectArrId = useSelector(selectSelectArrId);
  const selectedStartStop = useSelector(selectSelectedStartStop);
  const focusRawMapData = useSelector(selectFocusRawMapData);
  const focusDestinationId = useSelector(selectFocusDestinationId);
  const mapSelect = useSelector(selectMapSelect);
  


  useEffect(() => {
    if (!!stopList) return;
    dispatch(fetchStops());
  }, []);

  useEffect(() => {
    if (!!maps) return;
    dispatch(fetchMaps());
  }, []);


  useEffect(function fetchOnChange() {
    console.log("APPBASE selectArrId",selectedStartStop, selectArrId)
    
    if (!selectedStartStop || !selectArrId) return;

    dispatch(fetchTravelLegs(selectedStartStop, selectArrId.value));

  }, [dispatch, selectedStartStop, selectArrId])


//    dispatch(fetchDestinationDurationMap(mapSelect, content))



  return <div className="App">

    <Header/>

    <div style={{position: "absolute", 
                width: `calc(100% - ${SIDEBAR_WIDTH})`,
                height: "calc(100% - 30px)",
                top: "30px",
                right: "0"}}>
        <LeafletMap/>
  </div>

 <div>



       <Stack className="sidebar p-3 " gap={3}
             style={{width: SIDEBAR_WIDTH,
             height: "200px",
             backgroundColor:   "#eee",
             display: "flex",
             flexDirection: "column"}}>

      <CategoryList/>
      <Autocomplete />
      <DurationSelect/>


      {/* 
      <div style={{
        position: "absolute", fontSize: "x-small", right: "0px",
        maxHeight: "calc(100% - 36px)", overflowY: "scroll",
        bottom: "0px", zIndex: 1000000000, 
                  backgroundColor: "white"}}>
                    <ObjectList data={focusRawMapData}/>
                    <ObjectList data={{map: mapSelect}}/>
      </div>
 */}
  </Stack>


  <DestinationList/>

  </div>
  <DestinationOverlay/>

  </div>
}

export default App;
