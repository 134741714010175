import '../styles/map.css'; 

import { useEffect, useState } from 'react';
import { MapContainer } from 'react-leaflet/MapContainer'
import { MapLoading, Loading } from '../components/Loading';
import MapCutout from "./MapCutout"
import FocusMarker from "./FocusMarker"
import { useMapEvents } from "react-leaflet";
import LeafletMarkerCluster from "./LeafletMarkerCluster"
import { TransferRoute } from './TransferRoute';


import { useDispatch, useSelector } from 'react-redux';
import { fetchMapDestinations } from '../actions/destinationActions';
import { fetchStopTypes } from '../actions/stopsActions';
import { selectDurationMapData, selectFilteredRawMapData, selectFocusDestinationId, selectFocusRawMapData, selectHasCategoryStopTypesMap, selectHasDestinationsMap, selectMapSelect, selectMaps, selectMaxDuration, selectSelectCategory, selectSelectedStartStop, selectSelectedStartStopData, selectStopList, selectStopTypesMap, selectTravelLegsData } from '../selectors/selector';
import LeafletCanvasMarker from './LeafletCanvasMarker';
import { useSVGOverlayElement } from 'react-leaflet/SVGOverlay';
import MarkerStartStop from './MarkerStartStop';





function ZoomControl() {

    const [zoomLevel, setZoomLevel] = useState(null); // initial zoom level provided for MapContainer
    const mapEvents = useMapEvents({
        zoomend: () => {
            setZoomLevel(mapEvents.getZoom());
        },
    });

    return null
}


function LeafletMap () {

    const filteredRawMapData = useSelector(selectFilteredRawMapData);
    const mapSelect = useSelector(selectMapSelect);
    const maps = useSelector(selectMaps);
    const stopTypesMap = useSelector(selectStopTypesMap);
    const focusDestinationId = useSelector(selectFocusDestinationId);
    const durationMapData = useSelector(selectDurationMapData);
    const selectedStartStop = useSelector(selectSelectedStartStop);
    const selectCategory = useSelector(selectSelectCategory);
    const maxDuration = useSelector(selectMaxDuration);
    const travelLegsData = useSelector(selectTravelLegsData);
    const stopList = useSelector(selectStopList)
    const hasCategoryStopTypesMap = useSelector(selectHasCategoryStopTypesMap)
    const hasDestinationsMap = useSelector(selectHasDestinationsMap)
    
    const selectedStartStopData = useSelector(selectSelectedStartStopData)

    const focusRawMapData = useSelector(selectFocusRawMapData);
    
    const dispatch = useDispatch();

    const [ mapRef, setMapRef ] = useState(null);

    useEffect(function initRawDestinationMapData() {
        dispatch(fetchMapDestinations(mapSelect))
    }, [mapSelect, maps])

    useEffect(function wipeMapOnMapChange () {
        if (!mapRef) return;

        console.log("MAPSELECT CHANGE", mapRef.eachLayer((layer) => {
            
            if (!!layer.feature) {
                mapRef.removeLayer(layer)
            }
        }))
    }, [mapSelect])

    useEffect(() => {
        if (!!stopTypesMap) return;
        dispatch(fetchStopTypes("ch_winter"));
      }, []);


    useEffect(function flyOnDestinationFocusChange() {
        if (focusDestinationId?.source === "map") return;
        if (!focusRawMapData) return;

        const {lat, lon} = focusRawMapData

        console.log("LEAFLETMAP flyOnDestinationFocusChange", lat, lon, focusRawMapData)
        if (!lat | lat === 0) return;
        mapRef?.flyTo([lat, lon], 13);
    }, [focusDestinationId])


    useEffect(() => {
        console.log("LEAFLETMAP durationMapData", durationMapData)
    }, [durationMapData])

    return <>
           <MapContainer style={{position: "absolute", height: "100%", width: "100%"}}
                         bounds={[[47.721, 6.515], [45.886, 10.020]]}
                         ref={setMapRef}
                         className='custom-popup'
                         zoomSnap={0.25}  // better Switzerland fit
                         scrollWheelZoom={true} maxZoom={15}>
                <ZoomControl/>

                <LeafletMarkerCluster
                    focusDestinationId={focusDestinationId}
                    selectedStartStop={selectedStartStop}
                    rawMapData={filteredRawMapData}
                    durationMapData={durationMapData}
                    selectCategory={selectCategory}
                    maxDuration={maxDuration}
                />

            {!focusDestinationId ? null : 
                    <FocusMarker
                            selectCategory={selectCategory}
                            stopTypesMap={stopTypesMap}
                            categoryStopTypes={hasCategoryStopTypesMap}
                            selectedStartStop={selectedStartStop}
                            focusRawMapData={focusRawMapData} 
                            stopList={stopList}
                            focusDestinationId={focusDestinationId}
                            durationMapData={durationMapData}/>
            }

            {!travelLegsData ? null : <TransferRoute travelLegsData={travelLegsData} stopList={stopList} />}

            <MapCutout/>
            {/* {!mapLoading ? null: <MapLoading/>} */}

            {
                !selectedStartStopData ? null : <MarkerStartStop lat={selectedStartStopData.lat} lon={selectedStartStopData.lon} stopName={selectedStartStopData.name}/>
            }


            {!!hasDestinationsMap ? null : <LeafletCanvasMarker maxDuration={maxDuration}/> }
            </MapContainer>
        </>
}
  
  export default LeafletMap;