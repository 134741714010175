//import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import React, { useState, useEffect } from 'react';
// import Spinner from 'react-bootstrap/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { PropagateLoader, SyncLoader } from 'react-spinners';
// import { MDBCollapse } from 'mdb-react-ui-kit';
import {getURL, minutesToTimeStr} from '../utils/utils';
import Card from '@mui/joy/Card';
import AspectRatio from '@mui/joy/AspectRatio';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Typography from '@mui/joy/Typography';
import Divider from '@mui/joy/Divider';
import Chip from '@mui/joy/Chip';
import Cloud from '@mui/icons-material/Cloud';
import IconButton from '@mui/joy/IconButton';
import Close from '@mui/icons-material/Close';
import Minimize from '@mui/icons-material/Minimize';
import { ExpandCircleDown, ExpandLess, ExpandLessOutlined, ExpandLessTwoTone, ExpandMore, Maximize } from '@mui/icons-material';
import { Stack, Tooltip } from '@mui/joy';
import { useSelector } from 'react-redux';
import { selectMaxDuration, selectTravelLegsData } from '../selectors/selector';
import { Popover } from '@mui/material';
import { TravelLegsList } from "./Travelleg"
import ImageIcon from '@mui/icons-material/Image';

/* function CustomTooltip(props) {
  return (
    <Tooltip style={{ margin: '0 !important' }} id="button-tooltip" {...props}>
      Click for more information.
    </Tooltip>
  );
}
 */
function CategoryComponent({content}){

  if (!content?.destinationCategories) {
    return null;
  }

  const uniqueCategories = Array.from(new Set(Object.values(content.destinationCategories).flat()))

  return <div>
    { 
    uniqueCategories.map(
      (cat) => {
        return <Chip color="neutral" size="sm" variant="solid">{cat}</Chip>
    })}
    </div>
}

function FloatingCardHeader({minimized, setMinimized, content}) {

  return <Stack
  direction="row"
  justifyContent="space-between"
  alignItems="center"
  spacing={4}
>
<Typography level="title-md">{!minimized ? null : content?.destinationName}</Typography>

<Tooltip
  arrow
  color="neutral"
  placement="right"
  variant="solid"
  title={!minimized ? null : "Expand for more detail."}
>

<IconButton variant="plain" onClick={() => setMinimized(!minimized)}>  
  {!minimized ? <ExpandLess/> : <ExpandMore/>}
</IconButton>
</Tooltip>
</Stack>

}
function CardFooter({content, startStopName, maxDuration, duration}){

  return <>
    <Divider inset="context" />

    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={4}
      sx={{"padding": "5px"}}
    >

    <Typography level="body-xs" fontWeight="md" textColor="text.secondary">
      <CategoryComponent content={content}/>
    </Typography>

    <Divider orientation="vertical" />

    <Typography level="body-xs" fontWeight="md" textColor="text.secondary">
      
      {!duration ? null :
      <Chip color={duration / 60 <= maxDuration ? "primary" : "danger"} size="sm" variant="solid">
        {minutesToTimeStr(Math.floor(duration / 60))}
      </Chip> 
    }
    </Typography>
    </Stack>
  </>

}

function DestinationCard({
  destinationId, 
  startStopName,
  duration,
  style,
  destinationContent,
//  onDataFetched = () => {},
  onDestinationCardClick,
  noCollapse = false,
  simpleCard = false,
  toStopName,
}) {
  const [content, setContent] = useState(null);
  const [minimized, setMinimized] = useState(false);

  const maxDuration = useSelector(selectMaxDuration);
	const travelLegsData = useSelector(selectTravelLegsData)

  useEffect(() => {

    /* External content provided */
    if (!!destinationContent) {
      setContent({
        destinationName: destinationContent?.destination_name,
        destinationId: destinationContent?.destination_id,
        destinationAbstract: destinationContent?.abstract,
        destinationCategories: destinationContent?.categories,
        destinationUrl: destinationContent?.url,
        closest_stop_name: destinationContent?.closest_stop_name,
      });
      return;
    }

    /* Fetching single destination content */
    const url = getURL(`destination/${destinationId}`);

    fetch(url)
      .then((res) => res.json())
      .then((json) => {
        setContent({
          destinationName: json.destination_name,
          destinationId: json.destination_id,
          destinationAbstract: json.abstract,
          destinationCategories: json.categories,
          destinationUrl: json.url,
          closest_stop_name: json.closest_stop_name,
        });
      });
  }, [destinationId]);
  
  return (
          <Card className="m-20" 
            variant="outlined"
            orientation="vertical"
            sx={{ 
              width: "100%", cursor: noCollapse ? "default" : "pointer",
              "&:hover": {
                //backgroundColor: "rgba(0, 0, 0, 0.1)", // Light background color on hover
                boxShadow: "4px 4px 2px rgba(0, 0, 0, 0.2)" // Add shadow on hover
              }
          }}
            style={{ margin: "10px 0px" }}
            onClick={onDestinationCardClick}
            
          >
          {!!simpleCard ? null :
            <FloatingCardHeader minimized={minimized} setMinimized={setMinimized} content={content}/>
          }

          {!!minimized  ? null :
          <>   
            <CardContent orientation={simpleCard ? 'horizontal' : 'vertical'} >

            <AspectRatio ratio={simpleCard ? "1" : "2.5"} sx={{ minWidth: "40px!important" }}>
            <ImageIcon sx={{ fontSize: '3rem', opacity: 0.2 }} />
                {/* <img
                  src="https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318"
                  srcSet="https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318&dpr=2 2x"
                  loading="lazy"
                  alt=""
                /> */}
              </AspectRatio>

            <CardContent orientation='vertical' >

            <Typography level="title-md">{content?.destinationName}</Typography>
            {!!simpleCard ? null :
              <Typography level="text">
              <div
                className={`m-0 ${noCollapse ? '' : 'destination-card-text'}`}
                style={{ position: 'static' }}
                level="body-sm">
                <p className="m-0">{content?.destinationAbstract}</p>
                <p key="2" style={{ textAlign: 'right', fontSize: "smaller", fontStyle: "italic" }}>
                  <div >
                      learn more on<br/>
                      <a href={content?.destinationUrl}>
                        {!content ? null : (new URL(content?.destinationUrl)).hostname.replace(/^www\./, '') + ' '}
                        <FontAwesomeIcon fontSize='10' icon={faExternalLink} />
                      </a>
                  </div>
                </p>
              </div>
              </Typography>
            }
            </CardContent>
            </CardContent>

            </>
        }

        {!!minimized ? null : 


            <CardOverflow variant="soft" sx={{ bgcolor: 'background.level1' }}>

            {!!simpleCard ||  !travelLegsData ? null :
              <>
                <Typography level="titel-sm">Fastest connection from <b>{startStopName}</b></Typography>
                <Divider />
                <TravelLegsList/>
              </>
            }
            
            <CardFooter startStopName={startStopName} duration={duration} maxDuration={maxDuration} content={content}/>
            </CardOverflow>
          }

        </Card>
        );
      }

export default DestinationCard;
