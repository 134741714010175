function minutesToTimeStr(minutes) {
  const hour = Math.floor(minutes / 60);
  const minute = minutes % 60;
  const hourStr = hour === 0 ? '' : `${hour}h`;
//  const hourStr = hour === 0 ? '' : `${hour} hour${hour > 1 ? 's' : ''}`;

  const minuteStr = `${minute}m`;
  // const minuteStr = `${minute} minute${minute === 1 ? '' : 's'}`;

  return `${hourStr} ${minuteStr}`;
}


function getURL(endpoint) {

  const domain = process.env.NODE_ENV === "development" ? "http://localhost:5000" : "/api/"

  return `${domain}/${endpoint}`
}

export {minutesToTimeStr, getURL};
