import axios from 'axios';
import { getURL } from '../utils/utils';

export const SET_SELECTED_CATEGORY_OBJECT = 'SET_SELECTED_CATEGORY_OBJECT'

export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';


export const fetchCategoriesRequest = () => ({
  type: FETCH_CATEGORIES_REQUEST,
});


export const fetchCategoriesSuccess = (categories) => {
  return { type: FETCH_CATEGORIES_SUCCESS,
    payload: categories };
};

export const fetchCategoriesFailure = (error) => ({
  type: FETCH_CATEGORIES_FAILURE,
  payload: error,
});

export const setSelectedCategoryObject = (data) => ({
  type: SET_SELECTED_CATEGORY_OBJECT,
  payload: data
});

 
export const fetchCategories = (mapSelect) => {
  return (dispatch) => {
    dispatch(fetchCategoriesRequest());
    axios
      .get(getURL(`categories/${mapSelect}?`))
      .then((response) => {
        dispatch(fetchCategoriesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchCategoriesFailure(error.message));
      });
  }

}