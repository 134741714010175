import axios from "axios";
import { getURL } from "../utils/utils";

export const SET_FOCUS_DESTINATION_ID = 'SET_FOCUS_DESTINATION_ID';

// used by destinations (reduced info, but all loaded at once, not paginated)
export const FETCH_MAPDESTINATIONS_REQUEST = 'FETCH_MAPDESTINATIONS_REQUEST';
export const FETCH_MAPDESTINATIONS_SUCCESS = 'FETCH_MAPDESTINATIONS_SUCCESS';
export const FETCH_MAPDESTINATIONS_FAILURE = 'FETCH_MAPDESTINATIONS_FAILURE';

export const FETCH_DESTINATIONSDURATIONMAP_REQUEST = 'FETCH_DESTINATIONSDURATIONMAP_REQUEST';
export const FETCH_DESTINATIONSDURATIONMAP_SUCCESS = 'FETCH_DESTINATIONSDURATIONMAP_SUCCESS';
export const FETCH_DESTINATIONSDURATIONMAP_FAILURE = 'FETCH_DESTINATIONSDURATIONMAP_FAILURE';
export const RESET_DESTINATIONSDURATIONMAP = 'RESET_DESTINATIONSDURATIONMAP';



export const fetchMapDestinationsRequest = () => ({
  type: FETCH_MAPDESTINATIONS_REQUEST,
});

export const fetchMapDestinationsSuccess = (data) => {
  return { type: FETCH_MAPDESTINATIONS_SUCCESS,
    payload: data };
};

export const fetchMapDestinationsFailure = (error) => ({
  type: FETCH_MAPDESTINATIONS_FAILURE,
  payload: error,
});

export const fetchDestinationDurationMapRequest = () => ({
  type: FETCH_DESTINATIONSDURATIONMAP_REQUEST,
});

export const fetchDestinationDurationMapSuccess = (data) => {
  return { type: FETCH_DESTINATIONSDURATIONMAP_SUCCESS, payload: data };
};

export const fetchDestinationDurationMapFailure = (error) => ({
  type: FETCH_DESTINATIONSDURATIONMAP_FAILURE,
  payload: error,
});

export const resetDestinationDurationMap = () => ({
  type: RESET_DESTINATIONSDURATIONMAP,
});


export const setFocusDestinationId = (id) => ({
  type: SET_FOCUS_DESTINATION_ID,
  payload: id,
});


export const fetchMapDestinations = (mapSelect) => {
  return (dispatch) => {
    dispatch(fetchMapDestinationsRequest());

    const baseUrl = getURL(`destinationcoords/${mapSelect}?`)
    const url = baseUrl + new URLSearchParams({ "return-coords": true, "return-category": true})
    axios
      .get(url)
      .then((response) => {
        dispatch(fetchMapDestinationsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchMapDestinationsFailure(error.message));
      });
  }
}
export const fetchDestinationDurationMap = (mapSelect, depStopName) => {
  return (dispatch) => {
    dispatch(fetchDestinationDurationMapRequest());

    const baseUrl = getURL(`durationmap/${mapSelect}?`)
    const url = baseUrl + new URLSearchParams({ "start-stop-name": depStopName})
    axios
      .get(url)
      .then((response) => {
        dispatch(fetchDestinationDurationMapSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchDestinationDurationMapFailure(error.message));
      });
  }
}
