import "../styles/LeafletFeatures.css";
import { Polyline, CircleMarker } from 'react-leaflet';
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { selectFocusRawMapData, selectTravelLegsData } from "../selectors/selector";


function TransferRoute() {

  const stopList = useSelector((state) => state.stops.list);
	const travelLegsData = useSelector(selectTravelLegsData);
  const focusRawMapData = useSelector(selectFocusRawMapData);

  // local simplified coordinates list from 
  const [coordinates, setCoordinates] = useState(null);

  useEffect(function coordinatesChanged() {
      console.log("TRANSFERROUTE coordinates changed", coordinates)
  }, [coordinates])

	useEffect(() => {
		console.log("TRANSFERROUTE travelLegsData changed ", travelLegsData);
	}, [travelLegsData])


  
  useEffect(function travelLegDataChanged() {

    if (!travelLegsData || !stopList) {
        return 
    }

    setCoordinates(
        Object.values(travelLegsData.data).map(x => {
      return [stopList[x.start],
              ...x.stops_sequence.map(y => stopList[y]),
              stopList[x.stop]
              ].map(x => [x.lon, x.lat])
      })
    )
    }, [travelLegsData, stopList])


  console.log("TRANSFERROUTE return", focusRawMapData)

   /* !focusRawMapData ? null : <> */
   return !coordinates ? null : coordinates.map((line, index) =>{

          console.log("TRANSFERROUTE coordinates loop ", line, index);

          const line_lonlat = line.map(([lon, lat]) => ([lat, lon]))
          const startpoint = line_lonlat[0]
          const endpoint = line_lonlat[line_lonlat.length - 1]

          return <div key={`${index}--content`}>
            <Polyline
                          // key={index//line.properties.i
                          //       }
                          eventHandlers={{ // mouseover: () => setIHighlightLeg(line.properties.i),
                                           // mouseout: () => setIHighlightLeg(null)
                                          }} 
                                  
                                            className={"leaflet-selected-trip-lines"}
                          pathOptions ={{ color: "blue"//color: iHighlightLeg == line.properties.i ? "orange" : "blue" ,
                          }}
                          positions={line_lonlat} 
                          />
            <CircleMarker
                      center={startpoint}
                       pathOptions ={{ color: "blue",
                       fill: "blue",
                       fillOpacity: 1,
                       stroke: "black", 
                       radius: 5
                      }}/>
            <CircleMarker
                       center={endpoint}
                       pathOptions ={{ color: "blue",
                       fill: "green",
                       fillOpacity: 1,
                       radius: 5
                      }}/>
            </div>
       }
       )}

     {/*  {endpoints.map((points) => {
          return points.coordinates.map((lonlat, ilonlat) => {

            const [lon, lat] = lonlat;
            return getTripEndpoint(points.properties.i,
                                   posints.properties.i * 2 + ilonlat,
                                   [lat, lon])
          }) 
      })} */}

/* </> */



export { TransferRoute }