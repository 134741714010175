import { createSelector } from "@reduxjs/toolkit";


export const selectStopList = (state) => state.stops.list
export const selectFocusDestinationId = (state) => state.destinations.focusDestinationId
export const selectSelectedStartStop = (state) => state.stops.selectDepId
export const selectTravelLegsData = (state) => state.travellegs.travellegs
export const selectStopTypesMap = (state) => state.stops.types.map
export const selectMapSelect = (state) => state.maps.mapSelect
export const selectMaps = (state) => state.maps.maps
export const selectRawMapData = (state) => state.destinations.list;
/* {id_destination:
    {"duration_closest": 99,
     "stop_id_closest": 9999,
     "map_to_stop_duration": {idstop1: duration1, idstop2: duration2, ...},
     "destination_name": destinationname
   }}
*/
export const selectDurationMapData = (state) => state.destinations.durationMap.list;
export const selectSelectCategory = (state) => state.categories.select;
export const selectMaxDuration = (state) => state.duration.maxDuration;
export const selectSelectArrId = (state) => state.stops.selectArrId

function isEmptyArrayIntersection(array1, array2) {
    return !array1.some(element => array2.includes(element));
}

export const selectHasCategoryStopTypesMap = createSelector(
    [selectMaps, selectMapSelect], (maps, mapSelect) => {
        if (!maps || !mapSelect) return;
        return Object.values(maps).filter(x => x.map_name === mapSelect)[0]?.category_stop_types
    }
)

export const selectHasDestinationsMap = createSelector(
    [selectMaps, selectMapSelect], (maps, mapSelect) => {
        if (!maps || !mapSelect) return true;
        const hasDestinations = Object.values(maps).filter(x => x.map_name === mapSelect)[0]?.has_destinations;
        return hasDestinations;
    }
)

export const selectSelectedStartStopData = createSelector(
    [selectStopList, selectSelectedStartStop], (stopList, startStopName) => {
        if (!stopList) return null; 

        const filteredStops = Object.values(stopList).filter(x => x.name === startStopName);

        return (filteredStops.length === 1) ? filteredStops[0] : null
    }
)

export const selectFocusRawMapData = createSelector(
    [
        selectRawMapData,
        selectDurationMapData,
        selectSelectCategory,
        selectMaxDuration,
        selectFocusDestinationId,
        selectStopTypesMap,
        selectHasCategoryStopTypesMap
    ],
    (rawMapData,
     durationMapData,
     selectCategory,
     maxDuration,
     focusDestinationId,
     stopTypesMap,
     hasCategoryStopTypesMap
    ) => {
        /*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% 
        EARLY RETURN: NO FOCUS DESTINATION or OUT OF REACH DESTINATION or WRONG CATEGORY DESTINATION 
        %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/

        /* Early return no focus destination */
        if (!focusDestinationId ) {
            return null
        };

        console.log("SELECTFOCUSRAWMAPDATA focusDestinationId", focusDestinationId)
        console.log("SELECTFOCUSRAWMAPDATA durationMapData", durationMapData)

        console.log("SELECTFOCUSRAWMAPDATA durationMapData access 1", !!durationMapData ? durationMapData[focusDestinationId?.value] : null)

        /* Early return max destination */
        if (!!durationMapData && (!durationMapData ? null : durationMapData[focusDestinationId?.value]?.duration_closest) > maxDuration * 60) {
            return null
        };

        /* Initialize focus raw map data by slicing rawMapData with focus destination */
        const focusRawMapData = {...rawMapData[focusDestinationId.value]}

        console.log("SELECTOR %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%")

        /* Get list of relevant stop types from map-specific stopTypesMap 
        Note: Is null in case of stopTypes don't represent categories */
        if (hasCategoryStopTypesMap) {

            const allStopTypesThisDestination = Object.values(focusRawMapData.map_stop_to_stop_types).flat() // note: cannot use id_category_array for comparison since that contains parent categories!
            const allStopTypeCategoriesThisDestination = allStopTypesThisDestination.map((x) => stopTypesMap[x].id_category)

            /* Early return no category overlap */
            if (!!selectCategory && isEmptyArrayIntersection(selectCategory, allStopTypeCategoriesThisDestination)) {
                console.log("SELECTOR early exist cat")
                return null;
            }
        }

        /*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% 
        FILTER STOPS BY SELECTED CATEGORY AND DURATION 
        %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/

        /* Filtered stop types map; note: From the global map object */
        const stopTypesMapFiltered = (!selectCategory || !stopTypesMap || !hasCategoryStopTypesMap) 
                ? Object.keys(stopTypesMap).map(x => Number(x)) 
                : Object.entries(stopTypesMap)
                        .filter(([key, value]) => { 
                            return !value.hasOwnProperty('id_category') || selectCategory.includes(value.id_category)
                        })
                        .map(([key, value]) => Number(key));

        /* Reduced map_stop_to_stop_types with filtered types for each stop */
        let mapStopToStopTypes = Object();
        let mapStopToDuration = Object();
 
        console.log("SELECTFOCUSRAWMAPDATA focusRawMapData", !!focusRawMapData, focusRawMapData)
        console.log("SELECTFOCUSRAWMAPDATA focusRawMapData[map_stop_to_stop_types]", focusRawMapData["map_stop_to_stop_types"])
        

        if (!focusRawMapData?.map_stop_to_stop_types) {
            return focusRawMapData
        }

        /* Mapping map_stop_to_stop_types since this will be defined in all cases (startStop yes/no and cat stop types yes/no)*/
         Object.entries(focusRawMapData["map_stop_to_stop_types"])?.forEach(([stopId, stopTypesArray]) => {

            let newStopTypesArray = stopTypesArray;

            console.log("SELECTFOCUSRAWMAPDATA", stopId, newStopTypesArray)            

            if (hasCategoryStopTypesMap) {
                newStopTypesArray = stopTypesArray.filter(element => stopTypesMapFiltered.includes(element))
            }
            /* Do early exit stop type filtering */
            if (newStopTypesArray.length === 0) return;

            console.log("SELECTOR >> newStopTypesArray", stopId, newStopTypesArray)

            let newStopDuration = null;

            if (!!durationMapData) {
                console.log("SELECTFOCUSRAWMAPDATA durationMapData access 2, durationMapData[focusDestinationId?.value],", durationMapData[focusDestinationId?.value],)
                console.log("SELECTFOCUSRAWMAPDATA durationMapData access 2 durationMapData", durationMapData)
                console.log("SELECTFOCUSRAWMAPDATA durationMapData access 2 focusDestinationId?.value", focusDestinationId?.value)
                console.log("SELECTFOCUSRAWMAPDATA durationMapData access 2 stopId", stopId)
                console.log("SELECTFOCUSRAWMAPDATA durationMapData access 2 stopTypesArray", stopTypesArray)
                newStopDuration = !durationMapData ? null : durationMapData[focusDestinationId?.value]?.map_to_stop_duration[stopId]

                /* Early exit max duration */
                if (!newStopDuration || newStopDuration > maxDuration * 60) return;
            }

            /* Note: duration is null if no durationMapdata */
            mapStopToDuration[stopId] = newStopDuration;
            mapStopToStopTypes[stopId] = newStopTypesArray;

            
        })

        console.log("SELECTOR >> mapStopToDuration", mapStopToDuration)
        console.log("SELECTOR >> mapStopToStopTypes", mapStopToStopTypes)

        focusRawMapData["map_stop_to_stop_types"] = mapStopToStopTypes
        focusRawMapData["map_stop_to_duration"] = mapStopToDuration

        /*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% 
        TRANSLATE STOP TYPES ID
        %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/

        console.log("SELECTOR >> mapStopToStopTypes", mapStopToStopTypes)
        /* 
        const mapStopToStopTypeNames= Object.fromEntries(Object
                .entries(mapStopToStopTypes)
                .map(([idStop, idStopTypeList]) =>
                     {
                        return [idStop, idStopTypeList.map((idStopType) => stopTypesMap[idStopType].name_stop_type
                        
                        
                        )];
                    }))

        focusRawMapData["map_stop_to_stop_type_names"] = mapStopToStopTypeNames
 */

        /*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% 
        GET CLOSEST STOP AMONG FILTERED STOPS 
        %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/

        function findClosestStop(obj) {
            return Object.entries(obj).reduce((acc, [idStop, duration]) => {
                if (duration < acc.duration) {
                    return { idStop, duration };
                }
                return acc;
            }, { idStop: null, duration: Infinity });
        }
        


        const closestStop = (!durationMapData) ? null : findClosestStop(mapStopToDuration)
        focusRawMapData["closestStop"] = closestStop

        return focusRawMapData;
    }
)


export const selectFilteredRawMapData = createSelector(
    [selectRawMapData, selectDurationMapData, selectSelectCategory, selectMaxDuration],
    (rawMapData, durationMapData, selectCategory, maxDuration) => {

    if (!durationMapData && !selectCategory) {
        return rawMapData;
    }

    const filteredRawMapDataList = Object.entries(rawMapData).filter(([idDestination, destination]) => {

    /* Filter by duration data available at all for given destination. */
    if (!!durationMapData && !(idDestination in durationMapData)) {
          return false; 
    }

    if (!!durationMapData 
        && (idDestination in durationMapData)
    ) {
        console.log("SELECTFOCUSRAWMAPDATA durationMapData access 3", durationMapData[idDestination], durationMapData[idDestination].duration_closest)
    }

    /* Filter by duration value. */
    if (!!durationMapData 
        && (idDestination in durationMapData)
        && (durationMapData[idDestination].duration_closest > maxDuration * 60)) {
      return false;
    }
    
    /* Filter by selected category. */
    const catIntersect = selectCategory?.filter(x => destination.id_category_array?.includes(x))

    if (!!selectCategory && !!catIntersect && catIntersect.length === 0) {
        return false;
    }

    return true;
    })

    return Object.fromEntries(filteredRawMapDataList)
})




